import React from 'react';
import '../assets/css/main.css'
import base_logo from '../assets/images/baselogo_stacked.png'


class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false
    };
  }
  
  render() {     
    var image = this.props.image  
    
    return (
      <React.Fragment>
        <div>          
          <div className="welcome_container">
              <img className="welcomeImage" src={base_logo} />
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default Welcome
